import React, {useEffect} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Typography,
  Divider,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import { SectionHeader, DescriptionCta } from 'components/molecules';
import { Section } from 'components/organisms';
import {Helmet} from 'react-helmet';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    '& .description-cta__button-group': {
      flexWrap: 'nowrap',
    },
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  divider: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(5, 0),
    },
  },
  text: {
    marginTop: '1.5rem'
  }
}));

const PrivacyPolicy = () => {
  const classes = useStyles();
  
  useEffect(() => {
    var s = document.createElement("script");
    let tag = document.getElementsByTagName("script")[0];
    
    s.src="https://cdn.iubenda.com/iubenda.js";
    
    tag.parentNode.insertBefore(s,tag);
  }, []);
  
  return (
    <div className={classes.root}>
      <Helmet>
        <title>Formigio (tm) - Your privacy is important to us</title>
      </Helmet>
  
      <Section className={classes.pagePaddingTop}>
        <>
          <a href="https://www.iubenda.com/privacy-policy/64908959"
             className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed"
             title="Privacy Policy">Privacy Policy</a>
        </>
      </Section>
    </div>
  );
};

export default PrivacyPolicy;
