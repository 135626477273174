import React from 'react';
import PrivacyPolicy from 'views/PrivacyPolicy';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

const PrivacyPolicyPage = () => {
  return (
    <WithLayout
      component={PrivacyPolicy}
      layout={Main}
    />
  )
};

export default PrivacyPolicyPage;
